import { cabinetApi as api } from "../api";
export const addTagTypes = ["api"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      appAnalyticsAnalysisInclusionsList: build.query<
        AppAnalyticsAnalysisInclusionsListApiResponse,
        AppAnalyticsAnalysisInclusionsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/analysis-inclusions/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      appAnalyticsAnalysisInclusionsRetrieve: build.query<
        AppAnalyticsAnalysisInclusionsRetrieveApiResponse,
        AppAnalyticsAnalysisInclusionsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/analysis-inclusions/${queryArg}/`,
        }),
        providesTags: ["api"],
      }),
      appAnalyticsAnalyticsColorsList: build.query<
        AppAnalyticsAnalyticsColorsListApiResponse,
        AppAnalyticsAnalyticsColorsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/analytics-colors/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      appAnalyticsAnalyticsColorsUpdate: build.mutation<
        AppAnalyticsAnalyticsColorsUpdateApiResponse,
        AppAnalyticsAnalyticsColorsUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/analytics-colors/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.analyticsEventColor,
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsAnalyticsColorsPartialUpdate: build.mutation<
        AppAnalyticsAnalyticsColorsPartialUpdateApiResponse,
        AppAnalyticsAnalyticsColorsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/analytics-colors/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedAnalyticsEventColor,
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsConsolidatedLocationsList: build.query<
        AppAnalyticsConsolidatedLocationsListApiResponse,
        AppAnalyticsConsolidatedLocationsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/consolidated-locations/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      appAnalyticsConsolidatedLocationsRetrieve: build.query<
        AppAnalyticsConsolidatedLocationsRetrieveApiResponse,
        AppAnalyticsConsolidatedLocationsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/consolidated-locations/${queryArg}/`,
        }),
        providesTags: ["api"],
      }),
      appAnalyticsContactEventsList: build.query<
        AppAnalyticsContactEventsListApiResponse,
        AppAnalyticsContactEventsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/contact-events/`,
          params: {
            attendees: queryArg.attendees,
            attendees_in: queryArg.attendeesIn,
            attendees_isnull: queryArg.attendeesIsnull,
            distinct: queryArg.distinct,
            distinct_in: queryArg.distinctIn,
            distinct_isnull: queryArg.distinctIsnull,
            end_date: queryArg.endDate,
            end_date_gt: queryArg.endDateGt,
            end_date_gte: queryArg.endDateGte,
            end_date_in: queryArg.endDateIn,
            end_date_isnull: queryArg.endDateIsnull,
            end_date_lt: queryArg.endDateLt,
            end_date_lte: queryArg.endDateLte,
            id: queryArg.id,
            id_gt: queryArg.idGt,
            id_gte: queryArg.idGte,
            id_in: queryArg.idIn,
            id_isnull: queryArg.idIsnull,
            id_lt: queryArg.idLt,
            id_lte: queryArg.idLte,
            limit: queryArg.limit,
            offset: queryArg.offset,
            order_by: queryArg.orderBy,
            start_date: queryArg.startDate,
            start_date_gt: queryArg.startDateGt,
            start_date_gte: queryArg.startDateGte,
            start_date_in: queryArg.startDateIn,
            start_date_isnull: queryArg.startDateIsnull,
            start_date_lt: queryArg.startDateLt,
            start_date_lte: queryArg.startDateLte,
            title: queryArg.title,
            title_contains: queryArg.titleContains,
            title_endswith: queryArg.titleEndswith,
            title_icontains: queryArg.titleIcontains,
            title_in: queryArg.titleIn,
            title_isnull: queryArg.titleIsnull,
            title_startswith: queryArg.titleStartswith,
          },
        }),
        providesTags: ["api"],
      }),
      appAnalyticsEventCategoriesList: build.query<
        AppAnalyticsEventCategoriesListApiResponse,
        AppAnalyticsEventCategoriesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/event-categories/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      appAnalyticsEventCategoriesCreate: build.mutation<
        AppAnalyticsEventCategoriesCreateApiResponse,
        AppAnalyticsEventCategoriesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/event-categories/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsEventCategoriesRetrieve: build.query<
        AppAnalyticsEventCategoriesRetrieveApiResponse,
        AppAnalyticsEventCategoriesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/event-categories/${queryArg}/`,
        }),
        providesTags: ["api"],
      }),
      appAnalyticsEventCategoriesUpdate: build.mutation<
        AppAnalyticsEventCategoriesUpdateApiResponse,
        AppAnalyticsEventCategoriesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/event-categories/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.eventCategory,
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsEventCategoriesPartialUpdate: build.mutation<
        AppAnalyticsEventCategoriesPartialUpdateApiResponse,
        AppAnalyticsEventCategoriesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/event-categories/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedEventCategory,
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsEventCategoriesDestroy: build.mutation<
        AppAnalyticsEventCategoriesDestroyApiResponse,
        AppAnalyticsEventCategoriesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/event-categories/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsEventTypesList: build.query<
        AppAnalyticsEventTypesListApiResponse,
        AppAnalyticsEventTypesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/event-types/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      appAnalyticsEventTypesCreate: build.mutation<
        AppAnalyticsEventTypesCreateApiResponse,
        AppAnalyticsEventTypesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/event-types/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsEventTypesRetrieve: build.query<
        AppAnalyticsEventTypesRetrieveApiResponse,
        AppAnalyticsEventTypesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/event-types/${queryArg}/`,
        }),
        providesTags: ["api"],
      }),
      appAnalyticsEventTypesUpdate: build.mutation<
        AppAnalyticsEventTypesUpdateApiResponse,
        AppAnalyticsEventTypesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/event-types/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.eventType,
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsEventTypesPartialUpdate: build.mutation<
        AppAnalyticsEventTypesPartialUpdateApiResponse,
        AppAnalyticsEventTypesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/event-types/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedEventType,
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsEventTypesDestroy: build.mutation<
        AppAnalyticsEventTypesDestroyApiResponse,
        AppAnalyticsEventTypesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/event-types/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsEventsList: build.query<
        AppAnalyticsEventsListApiResponse,
        AppAnalyticsEventsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/events/`,
          params: {
            attendee_lookup: queryArg.attendeeLookup,
            attendees: queryArg.attendees,
            consolidated_location: queryArg.consolidatedLocation,
            date_scheduled: queryArg.dateScheduled,
            end_date: queryArg.endDate,
            event_category: queryArg.eventCategory,
            event_type: queryArg.eventType,
            inclusion: queryArg.inclusion,
            is_busy: queryArg.isBusy,
            leader: queryArg.leader,
            limit: queryArg.limit,
            offset: queryArg.offset,
            order_by: queryArg.orderBy,
            recurrence_type: queryArg.recurrenceType,
            search: queryArg.search,
            start_date: queryArg.startDate,
            status: queryArg.status,
            title: queryArg.title,
            topology: queryArg.topology,
          },
        }),
        providesTags: ["api"],
      }),
      appAnalyticsEventsUpdate: build.mutation<
        AppAnalyticsEventsUpdateApiResponse,
        AppAnalyticsEventsUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/events/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.leaderEvent,
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsEventsPartialUpdate: build.mutation<
        AppAnalyticsEventsPartialUpdateApiResponse,
        AppAnalyticsEventsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/events/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedLeaderEvent,
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsEventsGraphAnalyticsCreate: build.mutation<
        AppAnalyticsEventsGraphAnalyticsCreateApiResponse,
        AppAnalyticsEventsGraphAnalyticsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/events/graph-analytics/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsEventsResyncRetrieve: build.query<
        AppAnalyticsEventsResyncRetrieveApiResponse,
        AppAnalyticsEventsResyncRetrieveApiArg
      >({
        query: () => ({ url: `/api/v1_15/app-analytics/events/resync/` }),
        providesTags: ["api"],
      }),
      appAnalyticsExecutiveContactCategoriesList: build.query<
        AppAnalyticsExecutiveContactCategoriesListApiResponse,
        AppAnalyticsExecutiveContactCategoriesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/executive-contact-categories/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      appAnalyticsExecutiveContactCategoriesCreate: build.mutation<
        AppAnalyticsExecutiveContactCategoriesCreateApiResponse,
        AppAnalyticsExecutiveContactCategoriesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/executive-contact-categories/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsExecutiveContactCategoriesRetrieve: build.query<
        AppAnalyticsExecutiveContactCategoriesRetrieveApiResponse,
        AppAnalyticsExecutiveContactCategoriesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/executive-contact-categories/${queryArg}/`,
        }),
        providesTags: ["api"],
      }),
      appAnalyticsExecutiveContactCategoriesUpdate: build.mutation<
        AppAnalyticsExecutiveContactCategoriesUpdateApiResponse,
        AppAnalyticsExecutiveContactCategoriesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/executive-contact-categories/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.contactCategory,
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsExecutiveContactCategoriesPartialUpdate: build.mutation<
        AppAnalyticsExecutiveContactCategoriesPartialUpdateApiResponse,
        AppAnalyticsExecutiveContactCategoriesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/executive-contact-categories/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedContactCategory,
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsExecutiveContactCategoriesDestroy: build.mutation<
        AppAnalyticsExecutiveContactCategoriesDestroyApiResponse,
        AppAnalyticsExecutiveContactCategoriesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/executive-contact-categories/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsExecutiveContactTypesList: build.query<
        AppAnalyticsExecutiveContactTypesListApiResponse,
        AppAnalyticsExecutiveContactTypesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/executive-contact-types/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      appAnalyticsExecutiveContactTypesRetrieve: build.query<
        AppAnalyticsExecutiveContactTypesRetrieveApiResponse,
        AppAnalyticsExecutiveContactTypesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/executive-contact-types/${queryArg}/`,
        }),
        providesTags: ["api"],
      }),
      appAnalyticsExecutiveContactsRetrieve: build.query<
        AppAnalyticsExecutiveContactsRetrieveApiResponse,
        AppAnalyticsExecutiveContactsRetrieveApiArg
      >({
        query: () => ({ url: `/api/v1_15/app-analytics/executive-contacts/` }),
        providesTags: ["api"],
      }),
      appAnalyticsExecutiveContactsUpdate: build.mutation<
        AppAnalyticsExecutiveContactsUpdateApiResponse,
        AppAnalyticsExecutiveContactsUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/executive-contacts/${queryArg}/`,
          method: "PUT",
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsExecutiveContactsPartialUpdate: build.mutation<
        AppAnalyticsExecutiveContactsPartialUpdateApiResponse,
        AppAnalyticsExecutiveContactsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/executive-contacts/${queryArg}/`,
          method: "PATCH",
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsExecutiveContactsAttendeeAnalyticsCreate: build.mutation<
        AppAnalyticsExecutiveContactsAttendeeAnalyticsCreateApiResponse,
        AppAnalyticsExecutiveContactsAttendeeAnalyticsCreateApiArg
      >({
        query: () => ({
          url: `/api/v1_15/app-analytics/executive-contacts/attendee-analytics/`,
          method: "POST",
        }),
        invalidatesTags: ["api"],
      }),
      appAnalyticsRecurrenceTypesList: build.query<
        AppAnalyticsRecurrenceTypesListApiResponse,
        AppAnalyticsRecurrenceTypesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-analytics/recurrence-types/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as analyticsGenerated };
export type AppAnalyticsAnalysisInclusionsListApiResponse =
  /** status 200  */ PaginatedAnalysisInclusionList;
export type AppAnalyticsAnalysisInclusionsListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type AppAnalyticsAnalysisInclusionsRetrieveApiResponse =
  /** status 200  */ AnalysisInclusion;
export type AppAnalyticsAnalysisInclusionsRetrieveApiArg =
  /** A unique integer value identifying this analysis inclusion. */ number;
export type AppAnalyticsAnalyticsColorsListApiResponse =
  /** status 200  */ PaginatedAnalyticsEventColorList;
export type AppAnalyticsAnalyticsColorsListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type AppAnalyticsAnalyticsColorsUpdateApiResponse =
  /** status 200  */ AnalyticsEventColor;
export type AppAnalyticsAnalyticsColorsUpdateApiArg = {
  id: string;
  analyticsEventColor: AnalyticsEventColor;
};
export type AppAnalyticsAnalyticsColorsPartialUpdateApiResponse =
  /** status 200  */ AnalyticsEventColor;
export type AppAnalyticsAnalyticsColorsPartialUpdateApiArg = {
  id: string;
  patchedAnalyticsEventColor: PatchedAnalyticsEventColor;
};
export type AppAnalyticsConsolidatedLocationsListApiResponse =
  /** status 200  */ PaginatedConsolidatedLocationList;
export type AppAnalyticsConsolidatedLocationsListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type AppAnalyticsConsolidatedLocationsRetrieveApiResponse =
  /** status 200  */ ConsolidatedLocation;
export type AppAnalyticsConsolidatedLocationsRetrieveApiArg =
  /** A unique integer value identifying this consolidated location. */ number;
export type AppAnalyticsContactEventsListApiResponse =
  /** status 200  */ PaginatedAnalyticsEventList;
export type AppAnalyticsContactEventsListApiArg = {
  attendees?: number[];
  /** attendees (in) */
  attendeesIn?: string;
  /** attendees (is null) */
  attendeesIsnull?: boolean;
  /** * `event_idr` - event_idr
   * `icaluid` - icaluid
   * `event_unique_id` - event_unique_id */
  distinct?: "event_idr" | "event_unique_id" | "icaluid";
  /** distinct (in) */
  distinctIn?: string;
  /** distinct (is null) */
  distinctIsnull?: string;
  endDate?: string;
  /** end_date (greater than) */
  endDateGt?: string;
  /** end_date (greater than or equal to) */
  endDateGte?: string;
  /** end_date (in) */
  endDateIn?: string;
  /** end_date (is null) */
  endDateIsnull?: boolean;
  /** end_date (less than) */
  endDateLt?: string;
  /** end_date (less than or equal to) */
  endDateLte?: string;
  id?: number;
  /** id (greater than) */
  idGt?: number;
  /** id (greater than or equal to) */
  idGte?: number;
  /** id (in) */
  idIn?: string;
  /** id (is null) */
  idIsnull?: boolean;
  /** id (less than) */
  idLt?: number;
  /** id (less than or equal to) */
  idLte?: number;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Ordering
    
    * `start_date` - Start date
    * `-start_date` - Start date (descending)
    * `end_date` - End date
    * `-end_date` - End date (descending) */
  orderBy?: ("-end_date" | "-start_date" | "end_date" | "start_date")[];
  startDate?: string;
  /** start_date (greater than) */
  startDateGt?: string;
  /** start_date (greater than or equal to) */
  startDateGte?: string;
  /** start_date (in) */
  startDateIn?: string;
  /** start_date (is null) */
  startDateIsnull?: boolean;
  /** start_date (less than) */
  startDateLt?: string;
  /** start_date (less than or equal to) */
  startDateLte?: string;
  title?: string;
  /** title (contains) */
  titleContains?: string;
  /** title (endswith) */
  titleEndswith?: string;
  /** title (icontains) */
  titleIcontains?: string;
  /** title (in) */
  titleIn?: string;
  /** title (is null) */
  titleIsnull?: boolean;
  /** title (startswith) */
  titleStartswith?: string;
};
export type AppAnalyticsEventCategoriesListApiResponse =
  /** status 200  */ PaginatedEventCategoryList;
export type AppAnalyticsEventCategoriesListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type AppAnalyticsEventCategoriesCreateApiResponse =
  /** status 201  */ EventCategory;
export type AppAnalyticsEventCategoriesCreateApiArg = EventCategory;
export type AppAnalyticsEventCategoriesRetrieveApiResponse =
  /** status 200  */ EventCategory;
export type AppAnalyticsEventCategoriesRetrieveApiArg = string;
export type AppAnalyticsEventCategoriesUpdateApiResponse =
  /** status 200  */ EventCategory;
export type AppAnalyticsEventCategoriesUpdateApiArg = {
  id: string;
  eventCategory: EventCategory;
};
export type AppAnalyticsEventCategoriesPartialUpdateApiResponse =
  /** status 200  */ EventCategory;
export type AppAnalyticsEventCategoriesPartialUpdateApiArg = {
  id: string;
  patchedEventCategory: PatchedEventCategory;
};
export type AppAnalyticsEventCategoriesDestroyApiResponse = unknown;
export type AppAnalyticsEventCategoriesDestroyApiArg = string;
export type AppAnalyticsEventTypesListApiResponse =
  /** status 200  */ PaginatedEventTypeList;
export type AppAnalyticsEventTypesListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type AppAnalyticsEventTypesCreateApiResponse =
  /** status 201  */ EventType;
export type AppAnalyticsEventTypesCreateApiArg = EventType;
export type AppAnalyticsEventTypesRetrieveApiResponse =
  /** status 200  */ EventType;
export type AppAnalyticsEventTypesRetrieveApiArg = string;
export type AppAnalyticsEventTypesUpdateApiResponse =
  /** status 200  */ EventType;
export type AppAnalyticsEventTypesUpdateApiArg = {
  id: string;
  eventType: EventType;
};
export type AppAnalyticsEventTypesPartialUpdateApiResponse =
  /** status 200  */ EventType;
export type AppAnalyticsEventTypesPartialUpdateApiArg = {
  id: string;
  patchedEventType: PatchedEventType;
};
export type AppAnalyticsEventTypesDestroyApiResponse = unknown;
export type AppAnalyticsEventTypesDestroyApiArg = string;
export type AppAnalyticsEventsListApiResponse =
  /** status 200  */ PaginatedLeaderEventList;
export type AppAnalyticsEventsListApiArg = {
  attendeeLookup?: string;
  attendees?: string[];
  consolidatedLocation?: number[];
  dateScheduled?: string;
  endDate?: string;
  eventCategory?: number[];
  eventType?: number[];
  inclusion?: number[];
  isBusy?: boolean;
  leader?: number;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Ordering
    
    * `title` - Title
    * `-title` - Title (descending)
    * `date_scheduled` - Date scheduled
    * `-date_scheduled` - Date scheduled (descending)
    * `last_name` - Last name
    * `-last_name` - Last name (descending)
    * `status` - Status
    * `-status` - Status (descending)
    * `is_busy` - Is busy
    * `-is_busy` - Is busy (descending)
    * `leader` - Leader
    * `-leader` - Leader (descending)
    * `consolidated_location` - Consolidated location
    * `-consolidated_location` - Consolidated location (descending)
    * `start_date` - Start date
    * `-start_date` - Start date (descending)
    * `end_date` - End date
    * `-end_date` - End date (descending)
    * `event_type` - Event type
    * `-event_type` - Event type (descending)
    * `event_category` - Event category
    * `-event_category` - Event category (descending)
    * `attendees` - Attendees
    * `-attendees` - Attendees (descending)
    * `inclusion` - Inclusion
    * `-inclusion` - Inclusion (descending)
    * `recurring_root_event_idr` - Recurring root event idr
    * `-recurring_root_event_idr` - Recurring root event idr (descending) */
  orderBy?: (
    | "-attendees"
    | "-consolidated_location"
    | "-date_scheduled"
    | "-end_date"
    | "-event_category"
    | "-event_type"
    | "-inclusion"
    | "-is_busy"
    | "-last_name"
    | "-leader"
    | "-recurring_root_event_idr"
    | "-start_date"
    | "-status"
    | "-title"
    | "attendees"
    | "consolidated_location"
    | "date_scheduled"
    | "end_date"
    | "event_category"
    | "event_type"
    | "inclusion"
    | "is_busy"
    | "last_name"
    | "leader"
    | "recurring_root_event_idr"
    | "start_date"
    | "status"
    | "title"
  )[];
  recurrenceType?: number[];
  search?: string;
  startDate?: string;
  status?: string;
  title?: string;
  /** * `and` - And
   * `or` - Or */
  topology?: "and" | "or";
};
export type AppAnalyticsEventsUpdateApiResponse =
  /** status 200  */ LeaderEvent;
export type AppAnalyticsEventsUpdateApiArg = {
  /** A unique integer value identifying this leader event. */
  id: number;
  leaderEvent: LeaderEvent;
};
export type AppAnalyticsEventsPartialUpdateApiResponse =
  /** status 200  */ LeaderEvent;
export type AppAnalyticsEventsPartialUpdateApiArg = {
  /** A unique integer value identifying this leader event. */
  id: number;
  patchedLeaderEvent: PatchedLeaderEvent;
};
export type AppAnalyticsEventsGraphAnalyticsCreateApiResponse =
  /** status 200  */ LeaderEvent;
export type AppAnalyticsEventsGraphAnalyticsCreateApiArg = LeaderEvent;
export type AppAnalyticsEventsResyncRetrieveApiResponse =
  /** status 200  */ LeaderEvent;
export type AppAnalyticsEventsResyncRetrieveApiArg = void;
export type AppAnalyticsExecutiveContactCategoriesListApiResponse =
  /** status 200  */ PaginatedContactCategoryList;
export type AppAnalyticsExecutiveContactCategoriesListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type AppAnalyticsExecutiveContactCategoriesCreateApiResponse =
  /** status 201  */ ContactCategory;
export type AppAnalyticsExecutiveContactCategoriesCreateApiArg =
  ContactCategory;
export type AppAnalyticsExecutiveContactCategoriesRetrieveApiResponse =
  /** status 200  */ ContactCategory;
export type AppAnalyticsExecutiveContactCategoriesRetrieveApiArg =
  /** A unique integer value identifying this contact category. */ number;
export type AppAnalyticsExecutiveContactCategoriesUpdateApiResponse =
  /** status 200  */ ContactCategory;
export type AppAnalyticsExecutiveContactCategoriesUpdateApiArg = {
  /** A unique integer value identifying this contact category. */
  id: number;
  contactCategory: ContactCategory;
};
export type AppAnalyticsExecutiveContactCategoriesPartialUpdateApiResponse =
  /** status 200  */ ContactCategory;
export type AppAnalyticsExecutiveContactCategoriesPartialUpdateApiArg = {
  /** A unique integer value identifying this contact category. */
  id: number;
  patchedContactCategory: PatchedContactCategory;
};
export type AppAnalyticsExecutiveContactCategoriesDestroyApiResponse = unknown;
export type AppAnalyticsExecutiveContactCategoriesDestroyApiArg =
  /** A unique integer value identifying this contact category. */ number;
export type AppAnalyticsExecutiveContactTypesListApiResponse =
  /** status 200  */ PaginatedContactTypeList;
export type AppAnalyticsExecutiveContactTypesListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type AppAnalyticsExecutiveContactTypesRetrieveApiResponse =
  /** status 200  */ ContactType;
export type AppAnalyticsExecutiveContactTypesRetrieveApiArg =
  /** A unique integer value identifying this contact type. */ number;
export type AppAnalyticsExecutiveContactsRetrieveApiResponse = unknown;
export type AppAnalyticsExecutiveContactsRetrieveApiArg = void;
export type AppAnalyticsExecutiveContactsUpdateApiResponse = unknown;
export type AppAnalyticsExecutiveContactsUpdateApiArg =
  /** A unique integer value identifying this contact. */ number;
export type AppAnalyticsExecutiveContactsPartialUpdateApiResponse = unknown;
export type AppAnalyticsExecutiveContactsPartialUpdateApiArg =
  /** A unique integer value identifying this contact. */ number;
export type AppAnalyticsExecutiveContactsAttendeeAnalyticsCreateApiResponse =
  unknown;
export type AppAnalyticsExecutiveContactsAttendeeAnalyticsCreateApiArg = void;
export type AppAnalyticsRecurrenceTypesListApiResponse =
  /** status 200  */ PaginatedRecurrenceTypeList;
export type AppAnalyticsRecurrenceTypesListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type AnalysisInclusion = {
  id: number;
  name: string;
  color?: string;
};
export type PaginatedAnalysisInclusionList = {
  data: AnalysisInclusion[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type AnalyticsEventColor = {
  id: number;
  color?: string | null;
  organization: number;
  category?: string | null;
  calendar: number;
  event_category?: number | null;
  event_type?: number | null;
};
export type PaginatedAnalyticsEventColorList = {
  data: AnalyticsEventColor[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PatchedAnalyticsEventColor = {
  id?: number;
  color?: string | null;
  organization?: number;
  category?: string | null;
  calendar?: number;
  event_category?: number | null;
  event_type?: number | null;
};
export type ConsolidatedLocation = {
  id: number;
  name: string;
  color?: string;
};
export type PaginatedConsolidatedLocationList = {
  data: ConsolidatedLocation[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type ExternalEventLocation = {
  id: number;
  name: string;
  email?: string | null;
  /** * `virtual_conference` - Web Conference
   * `phone` - Phone Call
   * `in_person` - In Person
   * `other` - Other */
  location_type?: "virtual_conference" | "phone" | "in_person" | "other";
};
export type AnalyticsEvent = {
  id: number;
  event_idr: string;
  recurring_root_event_idr: string | null;
  title: string;
  attendees: number[];
  start_date: string;
  end_date: string;
  date_scheduled: string | null;
  cabinet_create_date: string;
  cabinet_update_date: string;
  /** * `SCHEDULED` - SCHEDULED
   * `CANCELED` - CANCELED
   * `IN_PROGRESS` - IN_PROGRESS */
  status: "SCHEDULED" | "CANCELED" | "IN_PROGRESS";
  is_busy: boolean;
  external_event_locations?: ExternalEventLocation[];
  meeting: number | null;
  conference_provider: number | null;
};
export type PaginatedAnalyticsEventList = {
  data: AnalyticsEvent[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type EventCategory = {
  id: number;
  name: string;
  color?: string;
  organization: number;
};
export type PaginatedEventCategoryList = {
  data: EventCategory[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PatchedEventCategory = {
  id?: number;
  name?: string;
  color?: string;
  organization?: number;
};
export type EventType = {
  id: number;
  name: string;
  color?: string;
  organization: number;
};
export type PaginatedEventTypeList = {
  data: EventType[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PatchedEventType = {
  id?: number;
  name?: string;
  color?: string;
  organization?: number;
};
export type LeaderEvent = {
  id: number;
  event_category?: number | null;
  event_type?: number | null;
  leader: number;
  recurring_root_event_idr: string;
  title: string;
  attendees: number[];
  start_date: string;
  end_date: string;
  date_scheduled: string;
  status: string;
  consolidated_location?: number | null;
  recurrence_type?: number | null;
  inclusion?: number;
  update_all?: boolean;
};
export type ContactDateValueMeta = {
  timezone: string;
};
export type ContactFieldMeta = {
  date_value?: ContactDateValueMeta | null;
};
export type ContactField = {
  text_value?: string | null;
  choices?: number[] | null;
  meta?: ContactFieldMeta | null;
};
export type Contact = {
  id: number;
  created_at?: string;
  updated_at?: string;
  timezone?: string | null;
  fields?: {
    [key: string]: ContactField;
  };
  tags?: string[];
  primary_email?: string | null;
  primary_email_hashed?: string | null;
  all_emails?: string[];
  all_emails_hashed?: string[];
  name: string | null;
  title?: string;
  is_resource?: boolean;
  last_observed_email?: string | null;
  last_observed_name?: string | null;
  avatar?: string | null;
  hidden_date?: string | null;
  contact_category?: number | null;
  organization?: number | null;
  contact_type?: number | null;
  company?: number | null;
  leaders: (number | null)[];
  private_leaders?: (number | null)[];
};
export type PaginatedLeaderEventList = {
  data: {
    events: {
      [key: string]: LeaderEvent;
    };
    contacts: {
      [key: string]: Contact;
    };
  };
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
    id_order?: number[];
    min_start_date?: string;
    max_end_date?: string;
    most_recent_log?: string;
    most_recent_started_log_date?: string;
    most_recent_completed_log_date?: string;
    most_recent_queued_log_date?: string;
  };
};
export type PatchedLeaderEvent = {
  id?: number;
  event_category?: number | null;
  event_type?: number | null;
  leader?: number;
  recurring_root_event_idr?: string;
  title?: string;
  attendees?: number[];
  start_date?: string;
  end_date?: string;
  date_scheduled?: string;
  status?: string;
  consolidated_location?: number | null;
  recurrence_type?: number | null;
  inclusion?: number;
  update_all?: boolean;
};
export type ContactCategory = {
  id: number;
  name: string;
  color?: string;
  organization?: number;
};
export type PaginatedContactCategoryList = {
  data: ContactCategory[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PatchedContactCategory = {
  id?: number;
  name?: string;
  color?: string;
  organization?: number;
};
export type ContactType = {
  id: number;
  name: string;
  color?: string;
};
export type PaginatedContactTypeList = {
  data: ContactType[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type RecurrenceType = {
  id: number;
  name: string;
  color?: string;
};
export type PaginatedRecurrenceTypeList = {
  data: RecurrenceType[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export const {
  useAppAnalyticsAnalysisInclusionsListQuery,
  useAppAnalyticsAnalysisInclusionsRetrieveQuery,
  useAppAnalyticsAnalyticsColorsListQuery,
  useAppAnalyticsAnalyticsColorsUpdateMutation,
  useAppAnalyticsAnalyticsColorsPartialUpdateMutation,
  useAppAnalyticsConsolidatedLocationsListQuery,
  useAppAnalyticsConsolidatedLocationsRetrieveQuery,
  useAppAnalyticsContactEventsListQuery,
  useAppAnalyticsEventCategoriesListQuery,
  useAppAnalyticsEventCategoriesCreateMutation,
  useAppAnalyticsEventCategoriesRetrieveQuery,
  useAppAnalyticsEventCategoriesUpdateMutation,
  useAppAnalyticsEventCategoriesPartialUpdateMutation,
  useAppAnalyticsEventCategoriesDestroyMutation,
  useAppAnalyticsEventTypesListQuery,
  useAppAnalyticsEventTypesCreateMutation,
  useAppAnalyticsEventTypesRetrieveQuery,
  useAppAnalyticsEventTypesUpdateMutation,
  useAppAnalyticsEventTypesPartialUpdateMutation,
  useAppAnalyticsEventTypesDestroyMutation,
  useAppAnalyticsEventsListQuery,
  useAppAnalyticsEventsUpdateMutation,
  useAppAnalyticsEventsPartialUpdateMutation,
  useAppAnalyticsEventsGraphAnalyticsCreateMutation,
  useAppAnalyticsEventsResyncRetrieveQuery,
  useAppAnalyticsExecutiveContactCategoriesListQuery,
  useAppAnalyticsExecutiveContactCategoriesCreateMutation,
  useAppAnalyticsExecutiveContactCategoriesRetrieveQuery,
  useAppAnalyticsExecutiveContactCategoriesUpdateMutation,
  useAppAnalyticsExecutiveContactCategoriesPartialUpdateMutation,
  useAppAnalyticsExecutiveContactCategoriesDestroyMutation,
  useAppAnalyticsExecutiveContactTypesListQuery,
  useAppAnalyticsExecutiveContactTypesRetrieveQuery,
  useAppAnalyticsExecutiveContactsRetrieveQuery,
  useAppAnalyticsExecutiveContactsUpdateMutation,
  useAppAnalyticsExecutiveContactsPartialUpdateMutation,
  useAppAnalyticsExecutiveContactsAttendeeAnalyticsCreateMutation,
  useAppAnalyticsRecurrenceTypesListQuery,
} = injectedRtkApi;
